.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba($color-white, 0.9);
  z-index: $z-index-header;

  &__inner {
    padding-left: 25px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1747px;
    margin: 0 auto;
    padding: 31px 0;
  }

  &__logo {
    width: 200px;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    background: url(/img/main_logo_greenweb.png) no-repeat;
    background-size: contain;
  }

  &__nav {
    &-list {
      display: flex;
      align-items: center;
    }

    &-item {
      padding: 0 25px;
    }

    &-link {
      display: block;
      height: 100%;
      font-weight: 900;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.42px;

      &.career {
        display: flex;
        align-items: center;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 12px;
          height: 11px;
          margin-left: 5px;
          background: url(/img/go_link.png) no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xmd) {
  .header {
    &__inner {
      padding: 0 15px 0 25px;
    }

    &__wrap {
      padding: 20px 0;
    }

    &__nav {
      &-item {
        padding: 0 15px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .header {
    &__inner {
      padding: 0;
    }

    &__wrap {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      padding: 0;
      background: $color-white;
    }

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 170px;
      height: 55px;
    }

    &__link {
      background-position: center;
    }

    &__nav {
      width: 100%;
      height: 45px;
      background: $color-green;
      color: $color-white;

      &-list {
        justify-content: center;
        height: 100%;
      }

      &-item {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;

        &.is-active {
          &::after {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 82%;
            height: 3px;
            background: $color-white;
            content: "";
          }
        }
      }

      &-link {
        display: flex;
        align-items: center;
        height: 100%;
        letter-spacing: 0.42px;
        text-align: center;

        &.career {
          &::after {
            background: url(/img/mo_go_link.png) no-repeat;
          }
        }
      }
    }
  }
}
