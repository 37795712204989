.#{$component-name} {
  .main-category {
    .common {
      &__breadcrumb {
        padding: 116px 0 101px;

        &-link {
          &:after {
            margin: 0 0 1px 4px;
          }
        }

        &-text {
          display: inline-block;
          margin-left: 8px;
          letter-spacing: 0.2px;
        }
      }
    }

    &__detail {
      .story-detail {
        &__title {
          &-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1350px;
            margin: 0 auto;
          }

          &-category {
            display: inline-block;
            line-height: 1.666;
            font-size: 12px;
            font-weight: 800;
            color: #00c74a;
            padding: 6px 16px 6px 14px;
            background-color: #e6faed;
            border-radius: 3px;
          }

          &-tit {
            margin-top: 10px;
            font-weight: 800;
            font-size: 46px;
            line-height: 1.26;
            letter-spacing: -2.3px;
          }

          &-date {
            display: block;
            margin-top: 24px;
            font-size: 12px;
            color: #777;
            font-weight: 700;
            line-height: 1.666;
            letter-spacing: -0.3px;
          }
        }

        &__share-box {
          padding-top: 1px;

          .btn-share {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url(/img/share_icon.png) no-repeat center;
            background-size: 100%;
          }
        }

        &__main {
          height: 600px;
          margin-top: 59px;
          margin-left: auto;
          margin-right: auto;
          background: no-repeat center;
          background-size: cover;
        }

        &-area {
          max-width: 1350px;
          margin: 60px auto 0;
          margin-top: 60px;
        }

        &__desc {
          display: block;
          color: #777;
          font-size: 16px;
          letter-spacing: -0.48px;
          line-height: 1.75;
        }

        &__page-nav {
          margin-top: 100px;
        }

        &__page-inner {
          margin: 0 auto;
          max-width: 1350px;
        }

        &__way-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 39px 0 39px 0;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }

        &__page-prev,
        &__page-next {
          position: relative;
          display: flex;
          width: 50%;
          align-items: center;
          line-height: 1.75;

          .story-detail__page-way {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.8px;
          }

          .story-detail__page-text {
            color: #777;
            letter-spacing: -0.48px;
          }
        }

        &__page-prev {
          &::before {
            width: 24px;
            height: 24px;
            background: url(/img/arrow_back.png) no-repeat center;
            background-size: 100%;
            content: "";
          }

          .story-detail__page-text {
            margin-left: 21px;
          }
        }

        &__btn-prev {
          margin-left: 5px;
        }

        &__page-next {
          justify-content: end;

          &::before {
            position: absolute;
            top: 0%;
            right: 100%;
            width: 1px;
            height: 100%;
            border-right: 1px solid #ddd;
            content: "";
          }

          &::after {
            width: 24px;
            height: 24px;
            content: "";
            background: url(/img/arrow_forward.png) no-repeat center;
            background-size: 100%;
            margin-left: 6px;
          }
        }

        &__btn-next {
          margin-left: 20px;
        }

        &__go-list {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__go-btn {
          display: flex;
          margin-top: 40px;
          align-items: center;
          justify-content: center;
          background-color: $color-default;
          padding: 16px 61px;
        }

        &__btn-text {
          line-height: 1.75;
          color: #fff;
          font-weight: 500;
          letter-spacing: -0.8px;
        }

        &__section {
          margin-top: 60px;

          .section-more-list {
            margin: 0 auto;
            max-width: 1350px;
          }

          .sub {
            &__list {
              margin-bottom: 174px;
            }

            &__item {
              border-bottom: 1px solid #e4e6ea;

              &:nth-of-type(1) {
                border-top: 1px solid #e4e6ea;
              }
            }

            &__link {
              display: flex;
              align-items: center;
              padding: 20px 0 19px 0;
            }

            &__img {
              width: 315px;
              height: 210px;
              background: no-repeat center;
              background-size: cover !important;
            }

            &__txt {
              flex: 3;
              margin-left: 50px;

              .tit-wrap {
                display: flex;
                flex-direction: column;

                .news {
                  &-date {
                    display: flex;
                    align-items: center;
                    color: #777;
                    font-size: 12px;
                    line-height: 1.666;
                    letter-spacing: -0.3px;

                    &::before {
                      display: inline-block;
                      width: 18px;
                      height: 18px;
                      background: url(/img/schedule2.png) no-repeat center;
                      background-size: 100%;
                      margin-right: 5px;
                      content: "";
                    }
                  }

                  &-tit {
                    margin-top: 6px;
                    line-height: 1.4;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: -1px;
                  }

                  &-desc {
                    margin-top: 10px;
                    font-size: 14px;
                    color: #777;
                    line-height: 1.857;
                    letter-spacing: -0.4px;
                  }
                }
              }
            }

            &__arrow {
              &::before {
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url(/img/arrow_forward_ios.png) no-repeat center;
                background-size: 100%;
                transform: translate(-1.4px, 1.4px);
                content: "";
              }
            }
          }
        }
      }
    }

    &__share-popup {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      .dimmed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0.6;
        z-index: 200;
      }

      .popup {
        &-inner {
          position: fixed;
          top: 0%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 201;
          background: #fff;
          transition: 0.3s;
        }

        &-contents {
          position: relative;
          left: 50%;
          padding: 40px;
          transform: translateX(-50%);
          z-index: 101;
        }

        &-headline {
          font-size: 22px;
          line-height: 1.545;
          text-align: center;
          letter-spacing: -1px;
          font-weight: 800;
        }

        &-share__list {
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          max-width: 268px;
        }

        &-share__item {
          display: flex;
          margin-bottom: 15px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-share__medium {
          width: 52px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 20px;

          &:nth-of-type(4n) {
            margin-right: 0;
          }

          .share-icon {
            width: 44px;
            height: 44px;
            margin-bottom: 5px;
          }

          .medium-text {
            font-size: 14px;
            line-height: 1.857;
            letter-spacing: -0.3px;
          }
        }

        &-close {
          position: absolute;
          width: 35px;
          height: 35px;
          top: -46px;
          right: 0;
          border: 0;
          background: none;
          z-index: 100;
          cursor: pointer;
        }
      }

      &.is-show {
        visibility: visible;
        opacity: 1;

        .popup-inner {
          top: 50%;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .main-category {
      .common {
        &__breadcrumb {
          padding: 118px 0 80px;

          &-wrap {
            padding-left: 25px;
          }
        }
      }

      &__detail {
        .story-detail {
          &-area {
            padding: 0 25px;
          }

          &__title {
            &-inner {
              padding: 0 25px;
            }
          }

          &__page-inner {
            padding: 0 25px;
          }

          &__section {
            .section-more-list {
              padding: 0 25px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .main-category {
      .common {
        &__breadcrumb {
          padding: 105px 0 31px;

          &-wrap {
            padding-left: 26px;
          }

          &-link {
            &:after {
              margin: 0 0 0 1px;
            }
          }

          &-text {
            margin-left: 5px;
          }
        }
      }

      &__detail {
        .story-detail {
          &__title {
            &-inner {
              padding: 0 25px;
            }

            &-tit {
              margin-top: 9px;
              font-size: 36px;
              line-height: 46px;
              letter-spacing: -1.8px;
            }

            &-date {
              margin-top: 22px;
            }
          }

          &__main {
            height: auto;
            margin-top: 49px;
            padding-top: 59.9%;
          }

          &-area {
            margin-top: 34px;
          }

          &__inner {
            padding: 0 27px;
          }

          &__text-list {
            max-width: 1317px;
            padding-left: 0;
          }

          &__text-item {
            margin-top: 25.5px;
          }

          &__desc {
            font-size: 14px;
            letter-spacing: -0.42px;
            line-height: 26px;
          }

          &__page-nav {
            margin-top: 30px;
          }

          &__page-inner {
            padding: 0 25px;
          }

          &__way-box {
            padding: 25px 0;
          }

          &__page-prev,
          &__page-next {
            .story-detail__page-way {
              font-size: 14px;
              letter-spacing: -0.42px;
            }

            .story-detail__page-text {
              font-size: 14px;
              letter-spacing: -0.42px;
            }
          }

          &__go-btn {
            padding: 14px 41.5px;
          }

          &__btn-text {
            font-size: 16px;
            line-height: 23px;
          }

          &__section {
            margin-top: 49px;

            .section-more-list {
              padding: 0 25px;
            }

            .sub {
              &__img {
                width: 36.21%;
                height: auto;
                padding-top: 24.1%;
              }

              &__txt {
                margin: 0 0 0 30px;

                .tit-wrap {
                  .news {
                    &-date {
                      line-height: 20px;
                      margin-bottom: 0;

                      &::before {
                        margin-top: -2px;
                      }
                    }

                    &-tit {
                      font-size: 18px;
                      line-height: 28px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .main-category {
      width: 100%;

      .common {
        &__breadcrumb {
          padding: 135px 0 34px;

          &-wrap {
            padding-left: 25px;
          }

          &-link {
            &:after {
              margin: 0 0 1px 1px;
            }
          }

          &-text {
            margin-top: 2px;
            letter-spacing: 0.3px;
          }
        }
      }

      &__detail {
        margin-top: -3px;

        .story-detail {
          &__title {
            &-inner {
              padding: 0;
              flex-direction: column;
            }

            &-box {
              text-align: center;
            }

            &-category {
              padding: 5px 9px 5px 10px;
            }

            &-tit {
              margin-top: 6px;
              font-size: 30px;
              letter-spacing: -1.5px;
            }

            &-date {
              margin-top: 8px;
            }
          }

          &__share-box {
            padding-top: 0;
            margin-top: 19px;

            .btn-share {
              width: 35px;
              height: 35px;
            }
          }

          &__main {
            height: auto;
            margin-top: 25px;
            padding-top: 60.47%;
          }

          &-area {
            margin-top: 31px;
          }

          &__text-item {
            margin-top: 23px;
          }

          &__desc {
            font-size: 14px;
            line-height: 1.642;
            letter-spacing: -0.42px;
          }

          &__way-box {
            padding: 14px 0 12px 0;
          }

          &__page-nav {
            margin-top: 49px;
          }

          &__page-inner {
            padding: 0 25px;
          }

          &__page-prev,
          &__page-next {
            .story-detail__page-way {
              font-size: 14px;
            }

            .story-detail__page-text {
              display: none;
            }
          }

          &__page-prev {
            &::before {
              width: 20px;
              height: 20px;
              margin-bottom: 1px;
            }
          }

          &__page-next {
            &::after {
              width: 20px;
              height: 20px;
              margin: -3px 0px 0 6px;
            }
          }

          &__go-btn {
            margin: 30px 0 0 0;
            padding: 14px 34px 13px 34px;
          }

          &__btn-text {
            font-size: 14px;
            letter-spacing: -0.42px;
            line-height: 1.642;
          }

          &__section {
            margin-top: 60px;

            .section-more-list {
              padding: 0 25px;
            }

            .sub {
              &__list {
                margin-bottom: 60px;
              }

              &__link {
                padding: 15px 0 14px 0;
              }

              &__img {
                width: 31.58%;
                height: auto;
                padding-top: 21.06%;
              }

              &__txt {
                margin: 0 0 0 20px;

                .tit-wrap {
                  .news {
                    &-date {
                      &::before {
                        width: 16px;
                        height: 16px;
                        transform: translateY(-1px);
                      }
                    }

                    &-tit {
                      margin-top: 1px;
                      font-size: 16px;
                      letter-spacing: -0.48px;
                    }

                    &-desc {
                      display: none;
                    }
                  }
                }
              }

              &__arrow {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
