body,
textarea,
input,
button {
  font-weight: 400;
  font-family: "NanumSquareNeo", sans-serif;
  color: $color-default;
  word-break: keep-all;
  word-wrap: break-word;
  -ms-word-break: keep-all;
  -ms-word-wrap: break-word;
}

img {
  width: 100%;
  vertical-align: top;
}

video {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  object-fit: cover;
  object-position: center top;
}

.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

.dim {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: $color-black;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.is-scroll-lock {
  overflow: hidden;
  height: 100%;
}

.container {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.br_mo {
  display: none;
}

.common {
  &__breadcrumb {
    &-wrap {
      display: flex;
      max-width: 1350px;
      margin: 0 auto;
      align-items: center;
    }

    &-link {
      &.icon {
        &:before {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url(/img/home_icon.png) no-repeat center;
          background-size: cover;
          vertical-align: middle;
          content: "";
        }
      }

      &:after {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0 8px 0 4px;
        margin-top: -1px;
        background: url(/img/chevron_right.png) no-repeat center;
        background-size: 100% 100%;
        vertical-align: bottom;
        content: "";
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    &-text {
      display: inline-block;
      font-size: 12px;
      color: #919191;
      font-weight: 800;
      letter-spacing: -0.36px;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .page {

    &-prev,
    &-next,
    &-prev__double,
    &-next__double {
      &::before {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: 100% !important;
        vertical-align: middle;
        content: "";
      }

      &.is-disabled {
        display: none;
      }
    }

    &-prev {
      &::before {
        background: url(/img/chevron_left.png) no-repeat center;
        margin-left: 3px;
      }
    }

    &-next {
      &::before {
        background: url(/img/chevron_right_02.png) no-repeat center;
        margin-right: 2px;
      }
    }

    &-prev__double {
      &::before {
        background: url(/img/double_arrow_left.png) no-repeat center;
      }
    }

    &-next__double {
      &::before {
        background: url(/img/double_arrow_right.png) no-repeat center;
      }
    }

    &-num-wrap {
      margin: 0 23px 0 20px;

      .page-blind.page-right-dot {
        margin-right: 17px;
      }

      .page-blind.page-left-dot {
        margin-right: 17px;
      }
    }

    &-link {
      font-size: 14px;
      font-weight: bold;
      color: #999;
      line-height: 1.857;
      position: relative;
      margin-right: 17px;
      letter-spacing: -0.48px;

      &.is-active {
        color: #00c74a;
        font-weight: 800;

        &::before {
          position: absolute;
          content: "";
          bottom: 0;
          width: 120%;
          height: 2px;
          background-color: #00c74a;
          bottom: -10px;
        }
      }

      &.is-disabled {
        display: none;
      }

      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .br_pc {
    display: none;
  }

  .br_mo {
    display: block;
  }

  .common {
    &__breadcrumb {
      &-link {
        &::after {
          margin: 0 3px 0 4px;
        }
      }
    }
  }
}
