.#{$component-name} {
  .privacy {
    * {
      box-sizing: border-box;
    }

    &__container-wrap {
      margin: 0 auto;
      max-width: 1350px;
    }

    .common {
      &__breadcrumb {
        padding: 116px 0 101px;

        &-text {
          margin-top: 2px;
        }
      }
    }

    &__title {
      &-text {
        display: block;
        font-size: 64px;
        letter-spacing: -3.84px;
        line-height: 1.093;
        font-weight: 800;
      }

      &-desc {
        margin-top: 19px;
        font-size: 16px;
        letter-spacing: -0.48px;
        line-height: 1.75;
        color: $color-text;
      }
    }

    &__btn-area {
      display: flex;
      justify-content: flex-end;
      margin-top: 60px;
    }

    &__btn {
      padding: 17px 20px 15px 19px;
      border: 1px solid #ddd;
      background-color: #fff;

      &-text {
        font-size: 14px;
        line-height: 1.857;
        font-weight: 800;
        letter-spacing: -0.6px;

        &::before {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: 0 8px 1px 0;
          background: url(/img/rewind_icon.png) no-repeat center;
          background-size: cover;
          vertical-align: middle;
          content: "";
        }
      }
    }

    &__table-area {
      margin-top: 40px;
    }

    &__table {
      width: 100%;

      &-head {
        border-bottom: 1px solid #bbb;
        border-top: 2px solid $color-green;
        background-color: #f3f4f9;
      }

      tr {
        &.privacy__table-body-tr {
          border-bottom: 1px solid #ddd;
        }
      }

      &-title {
        padding: 16px 43px 15px 30px;
        font-size: 16px;
        letter-spacing: -0.8px;
        line-height: 1.75;
        text-align: left;

        &:last-child {
          text-align: right;
        }
      }

      &-body {
        td {
          width: 100%;

          &:last-child {
            padding: 0 16px;
          }
        }
      }

      &-link {
        width: 100%;
        padding: 16px 26px 15px 30px;
        display: flex;
        justify-content: space-between;

        &--diff {
          padding: 16px 28px 15px 30px;
        }
      }

      &-course,
      &-date {
        font-size: 16px;
        letter-spacing: -0.48px;
        line-height: 1.75;
        color: $color-text;
      }

      &-date {
        min-width: 88px;
      }
    }

    .pagination {
      margin: 62px 0 129px;

      .page {
        &-num-wrap {
          margin: 0 25px 0 22px;
        }

        &-link {
          &:nth-of-type(4) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: $screen-md) {
  .#{$component-name} {
    .privacy {
      &__container-wrap {
        padding: 0 25px;
      }

      .common {
        &__breadcrumb {
          &-wrap {
            padding-left: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xmd) {
  .#{$component-name} {
    .privacy {
      &__container-wrap {
        padding: 0 25px;
      }

      .common {
        &__breadcrumb {
          padding: 105px 0 30px;

          &-wrap {
            padding-left: 26px;
          }

          &-link {
            &.icon {
              &:before {
                margin: 0;
              }
            }

            &:after {
              margin: 0 0 0 1px;
            }
          }

          &-text {
            margin: 0 0 0 5px;
          }
        }
      }

      &__title {
        &-text {
          font-size: 50px;
          letter-spacing: -4px;
          line-height: 60px;
        }

        &-desc {
          margin-top: 11px;
          font-size: 14px;
          letter-spacing: -0.42px;
          line-height: 23px;
        }
      }

      &__btn-area {
        margin-top: 34px;
      }

      &__btn {
        display: flex;
        width: 100%;
        padding: 13px 0 12px 0;
        align-items: center;
        justify-content: center;

        &-text {
          line-height: 23px;
        }
      }

      &__table-area {
        margin-top: 25px;
      }

      &__table {
        margin-bottom: 32px;

        &-title {
          padding: 16px 39px 15px 30px;
        }

        &-body {
          td {
            &:last-child {
              padding: 0 18px;
            }
          }
        }
      }

      .pagination {
        margin: 34px 0 134px 0;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .privacy {
      &__container-wrap {
        padding: 0 25px;
      }

      .common {
        &__breadcrumb {
          padding: 136px 0 29px;

          &-wrap {
            padding-left: 26px;
          }

          &-link {
            &.icon {
              &:before {
                margin: 0;
              }
            }

            &:after {
              margin: 0 0 1px 2px;
            }
          }

          &-text {
            margin-left: 5px;
          }
        }
      }

      &__title {
        &-inner {
          padding: 0;
        }

        &-text {
          font-size: 40px;
          letter-spacing: -3.2px;
          line-height: 1.25;
        }

        &-desc {
          margin-top: 11px;
          font-size: 14px;
          line-height: 1.642;
          letter-spacing: -0.42px;
        }
      }

      &__btn-area {
        margin-top: 44px;
        padding: 0;
      }

      &__btn {
        width: 100%;
        padding: 9px 0 11px 0;
        text-align: center;
      }

      &__table-area {
        margin-top: 30px;
      }

      &__table {
        border-top: 1px solid #ddd;
        margin-bottom: 31px;

        &-head {
          display: none;
        }

        &-body {
          tr {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
          }

          td {
            &:last-child {
              padding: 0;
            }
          }
        }

        &-link {
          padding: 15px 0 0 0;
          flex-direction: column;
        }

        &-course {
          display: block;
          margin-bottom: 1px;
          font-size: 14px;
          color: $color-default;
          line-height: 23px;
          letter-spacing: -0.42px;
          font-weight: 700;
        }

        &-date {
          display: block;
          padding: 0 0 13px;
          font-size: 12px;
          letter-spacing: -0.36px;
          line-height: 21px;
        }
      }

      &__table-area {
        padding: 0;
      }

      .pagination {
        margin-bottom: 65px;
      }
    }
  }
}
