.#{$component-name} {
  &.story-main {
    .main-category {
      .common {
        &__breadcrumb {
          padding: 117px 0 99px;

          &-link {
            &:after {
              margin: 0 0 1px 4px;
            }
          }

          &-text {
            display: inline-block;
            letter-spacing: 0.2px;
          }
        }
      }

      &__selector {

        * {
          box-sizing: border-box;
        }

        .dropdown {
          &__select {
            display: block;
            width: 180px;
            margin: 0 auto;
            position: relative;

            &.is-open {
              .dropdown__select-option {
                opacity: 1;
                visibility: visible;
              }

              .dropdown__selected-icon {
                &::before {
                  transform: translate(-50%, -50%) rotate(-180deg);
                }
              }
            }
          }

          &__select-option {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 180px;
            padding: 12px;
            border: 1px solid #ddd;
            background-color: #fff;
            z-index: 100;
            transition: 0.5s;
          }

          &__option-item {
            margin-top: 8px;

            &:first-of-type {
              margin-top: 0;
            }

            &.is-selected {
              .dropdown__option-link {
                background-color: #f3f4f9;
                color: #00c74a;
                font-weight: 800;
              }
            }
          }

          &__selected {
            &-value {
              width: 100%;
              display: flex;
              position: relative;
              height: 100%;
              font-weight: 600;
              border: none;
              background: none;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            &-text {
              display: flex;
              position: relative;
              font-family: "NanumSquareNeo";
              font-size: 16px;
              font-weight: bold;
              line-height: 28px;
              letter-spacing: -0.8px;
            }

            &-icon {
              width: 25px;
              height: 25px;
              margin-left: 16px;
              border-radius: 50%;
              box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.1);
              position: relative;

              &::before {
                position: absolute;
                width: 8px;
                height: 4px;
                background: url(/img/arrow_drop_down.png) no-repeat center;
                background-size: cover;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: center;
                transition: transform 0.5s;
                content: "";
              }
            }
          }

          &__select-option {
            top: 47px;
          }

          &__option-link {
            display: block;
            font-size: 14px;
            color: $color-text;
            padding: 11px 14px;
            font-weight: bold;
            transition: all ease 0.15s;
          }
        }
      }

      &__title {
        margin-top: 18px;
      }

      &-list {
        display: flex;
      }

      &__title-item {
        width: 50%;
        text-align: center;

        &.swiper-slide {
          &-active {
            a {
              color: #1d1d1f;
            }
          }

          &[data-category="culture"] {
            width: 48%;
          }
        }
      }

      &__title-link {
        display: flex;
        color: #dedede;
        font-weight: 800;
        font-size: 110px;
        line-height: 135px;
        transition: all 0.3s;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
      }

      &__contents {
        max-width: 1350px;
        margin: 112px auto 0;
        display: none;

        &-inner {
          margin-top: 99px;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 174px;
          }
        }

        &.is-show {
          display: block;
        }

        &-title {
          font-size: 28px;
          font-weight: 900;
          line-height: 1.357;
        }
      }

      &__title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-contents {
          &__more-text {
            display: flex;
            font-size: 16px;
            line-height: 1.75;
            align-items: center;

            &::after {
              margin-left: 6px;
              width: 22px;
              height: 22px;
              background: url(/img/expand_circle_right.png) no-repeat center;
              background-size: contain;
              content: "";
            }
          }
        }
      }

      &__contents-box {
        margin-top: 25px;

        .card-contents {
          margin: -39px 0 0 -30px;
          font-size: 0;

          &__item {
            display: inline-block;
            width: 430px;
            margin: 39px 0 0 30px;
            vertical-align: top;

            &:nth-of-type(3n + 1) {
              .card-contents__img {
                background: url(/img/story_contents_01.png) no-repeat center;
              }
            }

            &:nth-of-type(3n + 2) {
              .card-contents__img {
                background: url(/img/story_contents_02.png) no-repeat center;
              }
            }

            &:nth-of-type(3n + 3) {
              .card-contents__img {
                background: url(/img/story_contents_03.png) no-repeat center;
              }
            }
          }

          &__item-inner {
            display: block;
          }

          &__img {
            position: relative;
            background: url(/img/story_contents_01.png) no-repeat center;
            background-size: cover !important;
            padding-top: 66.75%;
          }

          &__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &__txt-box {
            display: flex;
            flex-direction: column;
            margin-top: 17px;

            .date {
              display: flex;
              color: rgba(119, 119, 119, .5);
              font-size: 12px;
              font-weight: bold;
              letter-spacing: -0.3px;
              align-items: center;

              &::before {
                display: inline-block;
                width: 18px;
                height: 18px;
                background: url(/img/schedule_02.png) no-repeat center;
                background-size: cover;
                margin-right: 6px;
                content: "";
              }
            }

            .title {
              font-size: 20px;
              font-weight: bold;
              line-height: 1.4;
              letter-spacing: -1px;
              margin-top: 6px;
            }
          }
        }

        .desc {
          margin-top: 11px;
          color: #777;
          font-size: 14px;
          line-height: 1.857;
          letter-spacing: -0.44px;
        }

        .pagination {
          height: 32px;
          padding-top: 70px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: $screen-md) {
  .#{$component-name} {
    &.story-main {
      .main-category {
        .common {
          &__breadcrumb {
            padding: 118px 0 80px;

            &-wrap {
              padding-left: 25px;
            }
          }
        }

        &__title-item {
          &.swiper-slide {
            &-active {
              a {
                color: #1d1d1f;
              }
            }
          }
        }

        &__title-link {
          font-size: 80px;
          line-height: 135px;
        }

        &__contents {
          margin-top: 80px;

          .card-contents {
            &__more-text {
              display: flex;
              font-size: 15px;

              &::after {
                margin-left: 4px;
                width: 20px;
                height: 20px;
              }
            }
          }

          &-title {
            font-size: 24px;
            font-weight: 900;
            line-height: 1.357;
          }
        }

        &__title-box {
          padding: 0 25px;
        }

        &__contents-box {
          padding: 0 25px;

          .card-contents {
            display: flex;
            margin: -20px 0 0 -20px;
            flex-wrap: wrap;

            &__item {
              width: calc((100% - 60px) / 3);
              margin: 20px 0 0 20px;
            }

            &__txt-box {
              display: flex;
              margin-top: 17px;
              flex-direction: column;

              .date {
                &::before {
                  width: 16px;
                  height: 16px;
                }
              }

              .title {
                font-size: 18px;
              }
            }
          }

          .desc {
            margin-top: 4px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: $screen-xmd) {
  .#{$component-name} {
    &.story-main {
      .main-category {
        .common {
          &__breadcrumb {
            padding: 105px 0 60px;

            &-wrap {
              padding-left: 26px;
            }

            &-link {
              &:after {
                margin: 0 0 1px 1px;
              }
            }
          }
        }

        &__title-link {
          font-size: 78px;
          line-height: 88px;
        }

        &__contents {
          margin-top: 68px;

          &-inner {
            margin-top: 61px;
            padding: 0 25px;

            &:last-of-type {
              margin-bottom: 130px;
            }
          }

          &-title {
            font-size: 22px;
            line-height: 38px;
          }
        }

        &__title-box {
          .card-contents {
            &__more-text {
              font-size: 14px;
              line-height: 26px;
            }
          }
        }

        &__contents-box {
          margin-top: 19px;

          .card-contents {
            width: 100%;
            margin: 0;

            &__item {
              display: flex;
              width: 100%;
              margin: 20px 0 0 0;
              align-items: center;

              &:first-of-type {
                margin-top: 0;
              }

              &:nth-of-type(2),
              &:nth-of-type(3) {
                margin: 20px 0 0 0;
              }
            }

            &__item-inner {
              display: flex;
              align-items: center;
            }

            &__img {
              width: 41.787%;
              padding-top: 27.86%;

            }

            &__txt {
              margin-left: 30px;
            }

            &__txt-box {
              margin-top: 0;

              .date {
                line-height: 20px;

                &::before {
                  margin: -2px 5px 0 0;
                }
              }

              .title {
                margin-top: 1px;
                font-size: 18px;
                line-height: 28px;
              }
            }
          }

          .desc {
            margin-top: 8px;
            line-height: 26px;
          }

          .pagination {
            height: 32px;
            padding-top: 30px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.story-main {
      .main-category {
        width: 100%;

        .common {
          &__breadcrumb {
            padding: 135px 0 33px;

            &-wrap {
              padding-left: 26px;
            }

            &-link {
              &:after {
                margin: 0 0 1px 2px;
              }
            }

            &-text {
              margin: 2px 0 0 4.5px;
            }
          }
        }

        &__selector {
          .dropdown {
            &__select {
              width: 160px;
            }

            &__select-option {
              width: 160px;
              padding: 10px 9px;
              top: 32px;
              transform: translateX(9px);
            }

            &__selected {
              &-value {
                transform: translateX(7px);
              }

              &-text {
                font-size: 14px;
                line-height: 23px;
                letter-spacing: -0.42px;
              }

              &-icon {
                margin-left: 10px;
                width: 22px;
                height: 22px;
              }
            }

            &__option-link {
              padding: 9px;
            }
          }
        }

        &__title-item {
          width: 62.5vw;

          &[data-category="episode"] {
            width: 63.5vw;
          }

          &[data-category="culture"] {
            width: 60vw;
          }
        }

        &__title-link {
          font-size: 48px;
          line-height: 58px;
        }

        &__container {
          width: 100%;
        }

        &__contents {
          margin-top: 65px;
          padding: 0 25px;

          &-inner {
            margin-top: 59px;

            &:last-of-type {
              margin-bottom: 56px;
            }
          }

          &-title {
            font-size: 22px;
            line-height: 1.454;
          }
        }

        &__title-box {
          padding: 0;

          .card-contents {
            &__more-text {
              font-size: 14px;
              line-height: 1.642;

              &::after {
                margin-left: 6px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        &__contents-box {
          margin-top: 14px;

          .card-contents {
            margin: -18.31px 0 0 0;

            &__item {
              width: 100%;
              margin: 18.31px 0 0 0;
            }

            &__img {
              padding-top: 66.85%;
            }

            &__txt-box {
              margin-top: 12px;

              .date {
                letter-spacing: -0.36px;

                &::before {
                  width: 16px;
                  height: 16px;
                  margin-right: 5px;
                }
              }

              .title {
                font-size: 16px;
                font-weight: 800;
                letter-spacing: -0.5px;
                margin-top: 6px;
              }
            }
          }

          .desc {
            margin-top: 9px;
            font-size: 12px;
            letter-spacing: -0.36px;
          }

          .pagination {
            padding-top: 33px;

            .page {

              &-prev,
              &-next,
              &-prev__double,
              &-next__double {
                &::before {
                  margin-bottom: 11px;
                }
              }
            }

            .page-num-wrap {
              margin: 0 23px 9px 20px;
            }
          }
        }
      }
    }
  }
}
