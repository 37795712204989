article,
aside,
audio,
body,
button,
canvas,
dd,
details,
dl,
dt,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
input,
legend,
li,
menu,
nav,
ol,
p,
section,
table,
td,
th,
ul {
  margin: 0;
  padding: 0;
}

fieldset,
img {
  border: 0;
}

img {
  vertical-align: top;
}

li,
ol,
ul {
  list-style: none;
}

address,
cite,
dfn,
em,
i {
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

table {
  border-collapse: collapse;
}

table caption {
  display: none !important;
}

textarea,
input {
  background: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

hr {
  display: none !important;
}

body,
html {
  height: 100%;
  background: #fff;
  font-size: 16px;
  line-height: 1.4;
  -webkit-text-size-adjust: none;
  font-family: "NanumSquareNeo";
}

input::-webkit-input-placeholder {
  color: #b2b2b2;
}

::-moz-placeholder {
  color: #b2b2b2;
}

:-ms-input-placeholder {
  color: #b2b2b2;
}

.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

@include breakpoint(mobile) {

  body,
  html {
    font-size: 14px;
    line-height: 1.25;
  }
}
