$component-name: "greenwebs";

$screen-xsm: 430px;
$screen-sm: 767px;
$screen-xmd: 1023px;
$screen-md: 1599px;
$screen-lg: 1919px;
$screen-xlg: 1920px;

$color-black: #000;
$color-white: #fff;
$color-green: #00c74a;
$color-default: #222;
$color-text: #777;

$z-index-minus: -1;
$z-index-zero: 0;
$z-index-minimum: 1;
$z-index-hundred: 100;
$z-index-header: 200;
$z-index-layer: 300;
$z-index-mo-nav: 1000;
