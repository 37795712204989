.#{$component-name} {
  .main {
    &__headline {
      font-weight: 900;
      font-size: 46px;
      line-height: 58px;
    }

    &__btn {
      &-arrow {
        display: block;
        flex-shrink: 0;
        position: relative;
        width: 152px;
        height: 152px;
        background-image: url(/img/kv_ellipse.png);
        background-position: center;
        background-size: 130px 130px;
        background-repeat: no-repeat;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 30px;
        }
      }

      &-rotate {
        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          animation: rotate 5s linear infinite;

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    &__kv {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &-upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        position: relative;
        max-width: 1365px;
        width: 100%;
        margin: 0 auto;
        padding: 208px 0 119px 15px;
        background: no-repeat right;
        background-position: bottom -19px right 31px;
        background-image: url(/img/kv_pattern.png);
      }

      &-title {
        font-weight: 300;
        font-size: 64px;
        line-height: 80px;
        letter-spacing: -5.12px;

        em {
          font-weight: 800;
        }

        .em-green {
          color: #00c74a;
        }
      }

      &-desc {
        margin-top: 24px;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.54px;
        color: $color-text;
      }

      &-visual {
        position: relative;
        width: 100%;
      }

      &-list {
        display: flex;
      }

      &-item {
        overflow: hidden;
        height: 600px;

        video {
          width: 101%;
        }

        picture {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &__visual {
      &-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: 13.88%;
        bottom: 25px;
        z-index: 1;

        .swiper-button-prev,
        .swiper-button-next {
          margin-top: 0;
          left: 0;
          right: 0;
        }

        .swiper-pagination-bullets {
          bottom: 0;
          text-indent: -9999px;
        }
      }

      &-arrow {
        flex-shrink: 0;
        position: relative;
        border: 0;
        background: none;

        &::after {
          content: "";
        }

        &--prev {
          width: 25px;
          height: 25px;
          background-image: url(/img/visual_arrow-left.png);
          background-repeat: no-repeat;
          background-position: center;
        }

        &--next {
          width: 25px;
          height: 25px;
          background-image: url(/img/visual_arrow-right.png);
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &-pagination {
        display: flex;
        position: relative;
        margin: 0 4px;

        .swiper-pagination-bullet {
          width: 7px;
          height: 7px;
          margin: 0 10px;
          background: none;
          border: 1px solid $color-white;
          opacity: 1;

          &-active {
            background: $color-white;
          }
        }
      }
    }

    &__company {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 200px 0 125px;
      background: #f3f4f9;

      &-wrap {
        display: flex;
        max-width: 1350px;
        width: 100%;
        position: relative;
      }

      &-info {
        max-width: 610px;
        margin-top: 77px;
      }

      .main__headline {
        letter-spacing: -2.3px;
      }

      &-desc {
        margin: 29px 0 100px 0;
        font-size: 16px;
        line-height: 28px;
        color: $color-text;
        letter-spacing: -0.48px;

        p {
          &:nth-child(2) {
            margin-top: 28px;
          }
        }
      }

      &-bg {
        flex-shrink: 0;
        width: 50%;
        height: 380px;
        margin-left: 65px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__works {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 1350px;
      margin: 0 auto;
      padding: 148px 0;

      &-content {
        margin-top: 16px;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        margin: 30px 0 0 30px;

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        &:nth-child(2) {
          .thumbnail {
            background-image: url(/img/works_thumbnail02.png);
          }
        }

        &:nth-child(3) {
          .thumbnail {
            background-image: url(/img/works_thumbnail03.png);
          }
        }

        &:nth-child(4) {
          .thumbnail {
            background-image: url(/img/works_thumbnail04.png);
          }
        }

        &:nth-child(5) {
          .thumbnail {
            background-image: url(/img/works_thumbnail05.png);
          }
        }

        &:nth-child(6) {
          .thumbnail {
            background-image: url(/img/works_thumbnail06.png);
          }
        }
      }

      &-link {
        display: block;
        width: 430px;
        height: 100%;
      }

      .thumbnail {
        padding-top: 56.279%;
        background: url(/img/works_thumbnail01.png) no-repeat center;
        background-size: cover;

        &-box {
          overflow: hidden;
        }
      }

      .title-box {
        padding: 29px 29px;
        border: 1px solid #ddd;
      }

      .title {
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -1px;
      }

      .desc {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        margin-top: 5px;
        font-size: 14px;
        line-height: 26px;
        color: $color-text;
        letter-spacing: -0.42px;
      }

      &-popup {
        .dim {
          display: none;
        }

        &.is-show {
          display: block;

          .popup__inner {
            top: 53%;
            opacity: 1;
            animation: isShow 0.5s linear;
          }

          .dim {
            display: block;
            animation: dimShow 0.2s linear;
          }
        }

        &.is-out {
          .popup__inner {
            top: -100%;
            opacity: 0;
            animation: isOut 0.7s linear;
          }

          .dim {
            display: none;
            animation: dimOut 0.5s linear;
          }
        }

        @keyframes dimShow {
          0% {
            opacity: 0;
          }

          90% {
            opacity: 0.29;
          }

          100% {
            opacity: 0.3;
          }
        }

        @keyframes dimOut {
          0% {
            display: block;
            opacity: 0.3;
          }

          100% {
            display: none;
            opacity: 0;
          }
        }

        @keyframes isShow {
          0% {
            top: 0;
            opacity: 0;
          }

          50% {
            opacity: 1;
          }

          100% {
            top: 53%;
          }
        }

        @keyframes isOut {
          0% {
            top: 53%;
            opacity: 1;
          }

          50% {
            opacity: 1;
          }

          100% {
            top: -100%;
            opacity: 0;
          }
        }

        &.popup {
          .popup-swiper {
            height: 100%;
          }

          .swiper-slide {
            overflow-y: auto;
            max-width: 977px;
            height: auto;
            margin: 0 3px 0 25px;
            padding: 0 25px 0 0;

            &::-webkit-scrollbar {
              width: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background: rgba(0, 0, 0, 0.2);
              border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, 0.08);
            }

            &:first-child {
              .popup__btn--prev {
                pointer-events: none;
              }
            }

            &:last-child {
              .popup__btn--next {
                pointer-events: none;
              }
            }

            &.slide-search .popup__img {
              background: url(/img/works_thumbnail01-2.png) no-repeat center;
              background-size: cover;
            }

            &.slide-monitor .popup__img {
              background: url(/img/works_thumbnail02.png) no-repeat center;
              background-size: cover;
            }

            &.slide-contents .popup__img {
              background: url(/img/works_thumbnail03.png) no-repeat center;
              background-size: cover;
            }

            &.slide-ad .popup__img {
              background: url(/img/works_thumbnail04.png) no-repeat center;
              background-size: cover;
            }

            &.slide-video .popup__img {
              background: url(/img/works_thumbnail05.png) no-repeat center;
              background-size: cover;
            }

            &.slide-partner .popup__img {
              background: url(/img/works_thumbnail06.png) no-repeat center;
              background-size: cover;
            }
          }
        }

        .popup {
          &__inner {
            display: inline-block;
            position: fixed;
            top: -100%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 1030px;
            height: calc(100vh - 270px);
            max-height: 754px;
            padding: 50px 25px;
            opacity: 0;
            transition: transform 0.5s ease-out, opacity 0.5s ease-out;
            background-color: $color-white;
            z-index: $z-index-layer;
          }

          &__contents {
            overflow: hidden;
            position: relative;
            z-index: $z-index-layer;
          }

          &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding-bottom: 24px;

            &::after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: #ddd;
              content: "";
            }
          }

          &__headline {
            font-weight: 800;
            font-size: 26px;
            line-height: 42px;
            letter-spacing: -1.3px;
          }

          &__date {
            display: flex;
            align-items: center;
            position: relative;
            font-size: 12px;
            color: $color-text;

            &::before {
              display: block;
              width: 18px;
              height: 18px;
              margin-right: 5px;
              background-image: url(/img/popup_schedule.png);
              content: "";
            }
          }

          &__img {
            width: 100%;
            height: 55.817%;
            margin-top: 30px;
            background-color: red;
            background-size: cover;
          }

          &__wrap {
            position: relative;
            margin-top: 30px;
            padding-bottom: 60px;

            &::after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: #ddd;
              content: "";
            }
          }

          &__box {
            margin-top: 25px;
          }

          &__title {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.48px;
          }

          &__desc {
            margin-top: 5px;
            font-size: 16px;
            line-height: 28px;
            color: $color-text;
            letter-spacing: -0.48px;
          }

          &__more {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 31px 0 30px;

            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 1px;
              height: 28px;
              background: #ddd;
              content: "";
            }

            &::after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: #ddd;
              content: "";
            }
          }

          &__link {
            display: flex;
            align-items: center;
            position: relative;
          }

          &__btn {
            display: flex;
            align-items: center;
            position: relative;
            border: 0;
            background: $color-white;
            font-weight: 800;
            font-size: 16px;
            letter-spacing: -0.48px;
            cursor: pointer;

            &--prev {
              margin-right: 20px;

              &::before {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                margin-right: 5px;
                background-image: url(/img/arrow_back.png);
                background-size: contain;
              }
            }

            &--next {
              margin-left: 20px;

              &::after {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                margin-left: 3px;
                background-image: url(/img/arrow_forward.png);
                background-size: contain;
              }
            }
          }

          &__post {
            font-size: 16px;
            color: $color-text;
            letter-spacing: -0.48px;
          }

          &__close {
            position: absolute;
            width: 66px;
            height: 66px;
            top: -66px;
            right: 0;
            border: 0;
            background: none;
            cursor: pointer;

            img {
              position: absolute;
              top: 0;
              right: 0;
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }

    &__contact {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 149px 0 148px;
      background: #f3f4f9;

      &-address {
        display: flex;
        max-width: 1350px;
        width: 100%;
        margin-top: 50px;

        &.map-gasan {
          margin-top: 47px;

          .main__map {
            background-image: url(/img/map_gasan.png);
          }
        }

        &.map-bundang {
          .main__map {
            background-image: url(/img/map_bundang.png);
          }
        }
      }

      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 410px;
        padding: 0 57px;
        font-size: 16px;
        background: #fff;
        box-sizing: border-box;
      }

      &-title {
        margin-bottom: 5px;
        font-weight: 800;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: -1.1px;
      }
    }

    &__address {
      display: flex;
      align-items: center;
      margin-top: 10px;

      &-item {
        position: relative;
        display: flex;
        align-items: center;

        &::before {
          display: block;
          width: 35px;
          height: 35px;
          background: #f3f4f9;
          border-radius: 50%;
          content: "";
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 9px;
          transform: translateY(-50%);
          width: 18px;
          height: 18px;
          background-image: url(/img/location_on.png);
          background-repeat: no-repeat;
          background-position: center;
          content: "";
        }
      }

      &:nth-child(3) {
        .main__address-item {
          &::after {
            background-image: url(/img/call.png);
          }
        }
      }

      &:nth-child(4) {
        .main__address-item {
          &::after {
            background-image: url(/img/mail.png);
          }
        }
      }

      &-text {
        margin-left: 10px;
        letter-spacing: -0.48px;
        font-family: "Pretendard", serif;
      }

      &-detail {
        margin-left: 11px;
        overflow: hidden;
        word-wrap: break-word;
        color: $color-text;
        letter-spacing: -0.48px;
      }
    }

    &__map {
      width: 50%;
      height: 410px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__service {
      display: flex;
      overflow: hidden;
      position: relative;
      margin-left: 30px;
      padding: 50px 0;

      &-list {
        display: flex;
        animation: marquee 18s linear infinite;

        @keyframes marquee {
          0% {
            transform: translateX(0%);
          }

          100% {
            transform: translateX(-100%);
          }
        }
      }

      &-item {
        flex-shrink: 0;
        width: 246px;
        height: 90px;
        margin-left: 30px;
        background: no-repeat center;

        &.naver {
          background-image: url(/img/partners_naver.png);
        }

        &.ins {
          background-image: url(/img/partners_naverins.png);
        }

        &.labs {
          background-image: url(/img/partners_naverlabs.png);
        }

        &.financial {
          background-image: url(/img/partners_naverfinancial.png);
        }

        &.connect {
          background-image: url(/img/partners_naverconnect.png);
        }

        &.webtoon {
          background-image: url(/img/partners_webtoon.png);
        }

        &.cloud {
          background-image: url(/img/partners_cloud.png);
        }

        &.happybean {
          background-image: url(/img/partners_happybean.png);
        }
      }

      &-link {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .main {
      &__works-popup {
        .popup__img {
          height: 64.582%;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .main {
      &__kv {
        &-text {
          padding-left: 40px;
        }

        &-upper {
          padding: 208px 0 120px;
        }
      }

      &__btn {
        &-arrow {
          margin: 0 40px;
        }
      }

      &__company {
        padding: 200px 40px 127px;

        &-bg {
          height: auto;
        }
      }

      &__works {
        &-content {
          margin-top: 20px;
        }

        &-item {
          width: 33.333%;
          margin: 30px 0 0 0;

          .title-box {
            padding: 20px;
          }
        }

        &-link {
          width: calc(100% - 30px);
          margin: 0 auto;
        }

        &-popup {
          &.popup {
            .swiper-slide {
              max-width: 867px;
              margin: 0 3px 0 20px;
              padding: 0 20px 0 0;
            }
          }

          .popup__img {
            height: 57.29%;
          }
        }

        .popup {
          &__inner {
            max-width: 910px;
            padding: 40px 20px;
          }
        }
      }

      &__contact {
        padding: 150px 40px 148px;
      }
    }
  }
}

@media screen and (max-width: $screen-xmd) {
  .#{$component-name} {
    .main {
      &__headline {
        font-size: 36px;
        text-align: center;
        line-height: 46px;
      }

      &__btn-arrow {
        display: none;
      }

      &__kv {
        &-upper {
          margin-top: 68px;
          padding: 50px 0 70px 0;
          background-size: contain;
        }

        &-text {
          padding-left: 24px;
        }

        &-title {
          font-size: 50px;
          line-height: 70px;
          letter-spacing: -4px;
        }

        &-desc {
          margin-top: 20px;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.48px;
        }

        &-item {
          height: 500px;
        }
      }

      &__visual {
        &-btn {
          right: 2.6%;
          bottom: 20px;
        }

        &-pagination {
          .swiper-pagination-bullet {
            margin: 0 7px;
          }
        }
      }

      &__company {
        padding: 100px 47px 150px 46px;

        .main__headline {
          letter-spacing: -1.8px;
        }

        &-wrap {
          flex-direction: column;
          padding: 0;
        }

        &-info {
          max-width: 100%;
          margin-top: 0;
        }

        &-desc {
          margin: 20px 0 0 0;
          text-align: center;
        }

        &-bg {
          width: 100%;
          margin: 66px 0 0 0;
        }
      }

      &__works {
        padding: 100px 15px 90px 15px;

        &-item {
          width: calc(50% - 20px);
          margin: 0;
          padding: 10px;

          .title-box {
            padding: 23px;
          }
        }

        &-link {
          width: 100%;
        }

        .title {
          font-size: 18px;
          letter-spacing: -0.9px;
        }

        &-popup {
          &.popup {
            .swiper-slide {
              max-width: 667px;
              margin: 0 8px 0 20px;
              padding: 0 15px 0 0;
            }
          }

          .popup__img {
            height: 44.03%;
          }
        }

        .popup {
          &__inner {
            max-width: 710px;
            padding: 25px 5px;
          }

          &__wrap {
            margin-top: 20px;
          }

          &__box {
            margin-top: 25px;

            &:first-child {
              margin-top: 20px;
            }
          }

          &__desc {
            font-size: 14px;
            line-height: 26px;
            letter-spacing: -0.42px;
          }
        }
      }

      &__contact {
        padding: 100px 24px;

        &-wrap {
          padding: 25px;
          height: 216px;
          order: 2;
        }

        &-title {
          line-height: 26px;
        }

        &-address {
          margin-top: 30px;
          flex-direction: column;

          &.map-gasan {
            margin-top: 30px;
          }
        }

        &-wrap {
          width: 100%;
        }

        &-title {
          font-size: 18px;
        }
      }

      &__map {
        width: 100%;
        height: 400px;
        order: 1;
      }

      &__address {
        align-items: center;

        &-item {
          width: 120px;
        }

        &-text {
          font-size: 14px;
        }

        &-detail {
          margin-left: 0;
          font-size: 14px;
        }
      }

      &__service {
        padding: 30px 0 35px 0;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .main {
      &__headline {
        font-size: 28px;
        text-align: center;
        line-height: 38px;
      }

      &__kv {
        &-upper {
          width: 100%;
          margin-top: 100px;
          padding: 35px 0 35px 0;
          background-size: 347px 307px;
          background-position: right -30px bottom -20px;
        }

        &-text {
          padding: 0 38px 0 25px;
        }

        &-title {
          font-size: 40px;
          line-height: 50px;
          letter-spacing: -3.2px;
        }

        &-desc {
          margin-top: 15px;
          font-size: 16px;
          line-height: 26px;
        }

        &-item {
          height: 287px;
        }
      }

      &__visual {
        &-btn {
          right: 20px;
          bottom: 14px;
        }

        &-pagination {
          .swiper-pagination-bullet {
            width: 5px;
            height: 5px;
            margin: 0 5px;
          }
        }
      }

      &__company {
        padding: 60px 25px;

        .main__headline {
          letter-spacing: -1.4px;
        }

        &-desc {
          margin: 16px 0 0 0;
          font-size: 14px;
          line-height: 23px;

          p {
            &:nth-child(2) {
              margin-top: 23px;
            }
          }
        }

        &-bg {
          margin: 40px 0 0 0;
        }
      }

      &__works {
        padding: 60px 25px;

        .main__headline {
          text-transform: uppercase;
        }

        &-content {
          margin-top: 30px;
        }

        &-item {
          width: 100%;
          padding: 0;

          .title-box {
            padding: 19px 20px 18px 20px;
          }

          &:nth-child(1) {
            .thumbnail {
              background-image: url(/img/mo_works_thumbnail01.png);
            }
          }

          &:nth-child(2) {
            .thumbnail {
              background-image: url(/img/mo_works_thumbnail02.png);
            }
          }

          &:nth-child(3) {
            .thumbnail {
              background-image: url(/img/mo_works_thumbnail03.png);
            }
          }

          &:nth-child(4) {
            .thumbnail {
              background-image: url(/img/mo_works_thumbnail04.png);
            }
          }

          &:nth-child(5) {
            .thumbnail {
              background-image: url(/img/mo_works_thumbnail05.png);
            }
          }

          &:nth-child(6) {
            .thumbnail {
              background-image: url(/img/mo_works_thumbnail06.png);
            }
          }
        }

        &-item {
          margin: 0 0 10px 0;

          &:last-child {
            margin: 0;
          }

          .title-box {
            padding: 19px 14px 18px 20px;
          }
        }

        .title {
          line-height: 26px;
        }

        .desc {
          margin-top: 4px;
          line-height: 23px;
        }

        &-popup {
          &.popup {
            .swiper-slide {
              max-width: calc(100% - 50px);
              margin: 0 3px 0 25px;
              padding: 0 22px 0 0;

              &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0);
              }

              &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0);
              }

              &.slide-search .popup__img {
                background: url(/img/mo_works_thumbnail01.png) no-repeat center;
                background-size: cover;
              }

              &.slide-monitor .popup__img {
                background: url(/img/mo_works_thumbnail02.png) no-repeat center;
                background-size: cover;
              }

              &.slide-contents .popup__img {
                background: url(/img/mo_works_thumbnail03.png) no-repeat center;
                background-size: cover;
              }

              &.slide-ad .popup__img {
                background: url(/img/mo_works_thumbnail04.png) no-repeat center;
                background-size: cover;
              }

              &.slide-video .popup__img {
                background: url(/img/mo_works_thumbnail05.png) no-repeat center;
                background-size: cover;
              }

              &.slide-partner .popup__img {
                background: url(/img/mo_works_thumbnail06.png) no-repeat center;
                background-size: cover;
              }
            }
          }

          .popup {
            &__inner {
              width: calc(100% - 50px);
              height: calc(100vh - 250px);
              padding: 17px 0 25px;
            }

            &__header {
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 12px;
            }

            &__headline {
              font-size: 18px;
              letter-spacing: -0.9px;
            }

            &__date {
              display: none;
            }

            &__img {
              height: 32.062%;
              margin-top: 20px;

              img {
                &.img-pc {
                  display: none;
                }

                &.img-mo {
                  display: block;
                }
              }
            }

            &__wrap {
              margin-top: 20px;
              padding-bottom: 30px;
            }

            &__box {
              margin-top: 25px;

              &:first-child {
                margin-top: 22px;
              }
            }

            &__title {
              font-size: 14px;
              line-height: 23px;
            }

            &__desc {
              line-height: 23px;
            }

            &__more {
              padding: 16px 0;
            }

            &__btn {
              font-size: 14px;

              &--prev {
                margin-right: 20px;

                &::before {
                  width: 20px;
                  height: 20px;
                }
              }

              &--next {
                margin-left: 20px;

                &::after {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            &__post {
              display: none;
            }

            &__close {
              width: 40px;
              height: 40px;
              top: -52px;

              img {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }

      &__contact {
        padding: 60px 25px;

        &-address {
          &.map-gasan {
            margin-top: 20px;
          }

          &.map-bundang {
            margin-top: 15px;
          }
        }

        &-wrap {
          height: 298px;
          padding: 20px;
          font-size: 14px;
        }
      }

      &__address {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
        line-height: 23px;

        &-item {
          &::before {
            width: 30px;
            height: 30px;
          }

          &::after {
            left: 7px;
            width: 16px;
            height: 16px;
          }
        }

        &-detail {
          margin: 5px 0 0 0;
        }
      }

      &__map {
        height: 230px;
      }

      &__service {
        gap: 15px;
        padding: 20px 0;

        &-list {
          gap: 15px;
        }

        &-item {
          width: 180px;
          height: 66px;

          &.naver {
            background-image: url(/img/mo_partners_naver.png);
          }

          &.ins {
            background-image: url(/img/mo_partners_naverins.png);
          }

          &.labs {
            background-image: url(/img/mo_partners_naverlabs.png);
          }

          &.financial {
            background-image: url(/img/mo_partners_naverfinancial.png);
          }

          &.connect {
            background-image: url(/img/mo_partners_naverconnect.png);
          }

          &.webtoon {
            background-image: url(/img/mo_partners_webtoon.png);
          }

          &.cloud {
            background-image: url(/img/mo_partners_cloud.png);
          }

          &.happybean {
            background-image: url(/img/mo_partners_happybean.png);
          }
        }
      }
    }
  }
}
