.footer {
  position: relative;
  border-top: 1px solid #ddd;

  &__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1350px;
    margin: 0 auto;
    padding: 43px 0 44px 0;
  }

  &__privacy {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.42px;
  }

  &__copyright {
    margin-top: 5px;
    font-size: 12px;
    line-height: 24px;
    color: #777;
  }

  &__family {
    &-wrap {
      position: relative;

      &.is-show {
        .footer__family-btn {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-btn {
      position: relative;
      width: 200px;
      padding: 8px 0 10px 0;
      border: none;
      border-bottom: 1px solid #222;
      background-color: $color-white;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      color: #222;
      letter-spacing: -0.48px;
      text-align: left;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 18px;
        right: 0;
        width: 11px;
        height: 6px;
        background: url(/img/btn_arrow.png) no-repeat;
        background-size: contain;
        transition: transform 0.3s ease;
      }
    }

    &-dropdown {
      display: none;
      overflow: hidden;
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #222;
      z-index: 20;
    }

    &-link {
      display: block;
      height: 36px;
      padding: 4px 14px;
      background-color: $color-white;
      font-size: 14px;
      line-height: 36px;
      color: #989898;
      letter-spacing: -0.42px;

      &:hover {
        background-color: #ddd;
        color: $color-black;
      }
    }
  }

  .btn-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: 100px;
    width: 70px;
    height: 70px;
    border: 0;
    border-radius: 50%;
    background: #00c74a;
    z-index: 10;
    cursor: pointer;

    img {
      width: 16px;
      height: 18px;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .footer {
    &__wrap {
      padding: 45px 40px;
    }
  }
}

@media screen and (max-width: $screen-xmd) {
  .footer {
    &__wrap {
      padding: 43px 25px;
    }

    .btn-top {
      top: -30px;
      width: 60px;
      height: 60px;
      right: 25px;
      bottom: 110px;
    }
  }
}

@include breakpoint(mobile) {
  .footer {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      padding: 28px 25px 23px;
    }

    &__privacy {
      font-family: "Pretendard", serif;
    }

    &__copyright {
      margin-top: 0;
    }

    &__family {
      width: 100%;
      margin-top: 23px;

      &-btn {
        width: 100%;
        padding-bottom: 9px;
        font-family: "NanumSquareNeo", sans-serif;
      }
    }

    .btn-top {
      top: -25px;
      right: 25px;
      width: 50px;
      height: 50px;

      img {
        width: 13.33px;
        height: 15px;
      }
    }
  }
}
