.#{$component-name} {
  .privacy-policy {
    max-width: 1350px;
    margin: 118px auto 0;

    &__title {
      font-weight: 800;
      font-size: 64px;
      line-height: 70px;
      letter-spacing: -3.84px;

      &-inner {
        margin-top: 100px;
      }
    }

    &__box {
      position: relative;
      padding: 57px 0 60px;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #ddd;
        content: "";
      }

      &.policy {
        padding-top: 41px;

        .privacy-policy__text {
          margin-top: 14px;
        }
      }

      &.change {
        padding-bottom: 40px;

        .privacy-policy__item-detail {
          &:first-child {
            margin-top: 20px;
          }
        }

        &::after {
          display: none;
        }
      }
    }

    &__headline {
      font-weight: 800;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -1.1px;

      &.policy {
        font-size: 22px;
      }
    }

    &__text {
      margin-top: 7px;
      font-size: 16px;
      line-height: 28px;
      color: $color-text;
      letter-spacing: -0.48px;

      &.apply {
        margin-top: 3px;
      }

      &.paragraph {
        margin-top: 11px;
      }

      &.noti {
        margin-top: 20px;
      }

      &.provide {
        margin-top: 20px;
      }

      &.contact {
        margin-top: 20px;
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 224px;
      height: 58px;
      border: 1px solid #ddd;
      font-weight: 800;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.7px;

      &::before {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        background-image: url(/img/go_return_link.png);
        background-repeat: no-repeat;
        background-position: center;
        content: "";
      }

      &-wrap {
        display: flex;
        justify-content: flex-end;
        margin: 59px 0 40px 0;
      }

      &-wrapper {
        display: flex;
        justify-content: center;
        margin: 80px 0 126px;
      }

      &-list {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 60px;
        background: $color-black;
        font-weight: 700;
        line-height: 28px;
        color: $color-white;
        letter-spacing: -0.8px;
      }
    }

    &__policy {
      &-list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-top: 40px;
        padding: 35px 0 41px 0;
        background: #f3f4f9;

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1px;
          height: calc(100% - 80px);
          background: #ddd;
          content: "";
        }
      }

      &-item {
        width: calc(50% - 40px);
        margin: 5px 0 0 40px;
        font-size: 16px;
        line-height: 28px;
        color: $color-text;
        letter-spacing: -0.48px;
      }
    }

    &__info {
      display: block;
      margin-top: 17px;
      line-height: 28px;
      letter-spacing: -0.48px;

      &.apply {
        font-weight: 800;
      }

      &.second {
        font-weight: 800;
        margin-top: 21px;
      }
    }

    &__list {
      &-info {
        margin-top: 10px;
        font-weight: 800;
        letter-spacing: -0.48px;

        &.apply {
          font-weight: 700;
          letter-spacing: -0.8px;
        }
      }

      &-detail {
        margin-top: 5px;
      }

      &-wrapper {
        margin-top: 10px;
      }

      &-wrap {
        margin-top: 20px;
      }
    }

    &__item {
      margin-top: 23px;

      &:nth-child(2) {
        margin-top: 24px;
      }

      &-detail {
        position: relative;
        margin-top: 3px;
        padding-left: 12px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.48px;
        color: $color-text;

        &::before {
          display: block;
          position: absolute;
          top: 13px;
          left: 0;
          width: 7px;
          height: 1px;
          background-color: $color-text;
          content: "";
        }

        &.info {
          margin-left: 21px;
        }

        &.inquiry {
          margin-left: 21px;
        }
      }

      &.apply {
        margin-top: 13px;
      }
    }

    &__table {
      width: 100%;
      margin-top: 20px;
      border-top: 2px solid $color-green;

      &.mo {
        display: none;
      }
    }

    &__td {
      height: 58px;
      border: solid #d2d2d2;
      border-width: 0 1px 1px 0;
      text-align: center;
      color: $color-text;
      letter-spacing: -0.48px;

      &:nth-child(1) {
        width: 300px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 525px;
      }

      &:nth-child(3n) {
        border-right: 0;
      }

      &-title {
        background: #f3f4f9;
        font-weight: 800;
        color: $color-black;
        letter-spacing: -0.8px;
      }
    }

    &__responsible {
      position: relative;
      width: 50%;
      padding: 40px;
      background: #f3f4f9;
      box-sizing: border-box;

      &-wrap {
        display: flex;
        width: 100%;
        margin-top: 20px;
      }

      &-title {
        line-height: 28px;
        letter-spacing: -0.8px;
      }

      &-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        margin-top: 2px;
        color: $color-text;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.48px;

        &::before {
          display: block;
          width: 3px;
          height: 3px;
          margin-right: 5px;
          border-radius: 50%;
          background: $color-black;
          content: "";
        }

        &:first-child {
          margin-top: 7px;
        }
      }

      &-mail {
        margin-left: 4px;
      }

      &::before {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 80px);
        background: #ddd;
        content: "";
      }

      &:nth-child(2) {
        &::before {
          display: none;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .privacy-policy {
      padding: 0 25px;
    }
  }
}

@media screen and (max-width: $screen-xmd) {
  .#{$component-name} {
    .privacy-policy {
      padding: 0 25px;
      margin: 105px auto 0;

      .common {
        &__breadcrumb {
          &-link {
            &.icon {
              &:before {
                margin: 0 2px 0 1px;
              }
            }

            &:after {
              margin: 0 4px 0 0;
            }
          }

          &-text {
            margin-top: 2px;
          }
        }
      }

      &__title {
        font-size: 50px;
        line-height: 60px;
        letter-spacing: -4px;

        &-inner {
          margin-top: 30px;
        }
      }

      &__box {
        position: relative;
        padding: 57px 0 60px;

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #ddd;
          content: "";
        }

        &.policy {
          padding-top: 41px;

          .privacy-policy__text {
            margin-top: 14px;
          }
        }

        &.change {
          padding-bottom: 40px;

          .privacy-policy__item-detail {
            &:first-child {
              margin-top: 20px;
            }
          }

          &::after {
            display: none;
          }
        }
      }

      &__headline {
        font-weight: 800;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: -1.1px;

        &.policy {
          font-size: 22px;
        }
      }

      &__text {
        margin-top: 7px;
        font-size: 16px;
        line-height: 28px;
        color: $color-text;
        letter-spacing: -0.48px;

        &.apply {
          margin-top: 3px;
        }

        &.paragraph {
          margin-top: 11px;
        }

        &.noti {
          margin-top: 10px;
          font-size: 14px;
          letter-spacing: -0.42px;
          line-height: 23px;
        }

        &.provide {
          margin-top: 20px;
        }

        &.contact {
          margin-top: 20px;
        }
      }

      &__link {
        width: 100%;
        height: 49px;

        &-wrap {
          margin: 35px 0 40px 0;
        }

        &-wrapper {
          margin: 40px 0 130px;
        }

        &-list {
          width: 140px;
          height: 50px;
          font-size: 16px;
          letter-spacing: -0.48px;
          line-height: 23px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .privacy-policy {
      padding: 30px 25px 60px 25px;

      &__title {
        font-size: 40px;
        letter-spacing: -3.2px;

        &-inner {
          margin-top: 25px;
        }
      }

      &__text {
        margin-top: 6px;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: -0.42px;

        &.noti {
          margin-top: 6px;
        }

        &.paragraph {
          margin-top: 8px;
        }

        &.apply {
          margin-top: 1px;
        }

        &.provide {
          margin-top: 15px;
        }

        &.contact {
          margin-top: 17px;
        }
      }

      &__link {
        width: 100%;
        height: 43px;

        &-wrap {
          margin-top: 44px;
          margin-bottom: 30px;
        }

        &-wrapper {
          margin: 50px 0 0;
        }

        &-list {
          width: 120px;
          height: 50px;
        }
      }

      &__box {
        padding: 31px 0 33px 0;

        &.policy {
          padding-top: 27px;

          .privacy-policy__text {
            margin-top: 8px;
          }
        }

        &.change {
          padding-bottom: 0;

          .privacy-policy__item-detail {
            &:first-child {
              margin-top: 14px;
            }
          }
        }
      }

      &__headline {
        font-size: 18px;
        letter-spacing: -0.9px;

        &.policy {
          font-size: 20px;
        }
      }

      &__policy {
        &-list {
          margin-top: 19px;
          padding: 23px 25px;

          &::before {
            display: none;
          }
        }

        &-item {
          width: 100%;
          margin: 0;
          font-size: 14px;
        }
      }

      &__list {
        &-wrap {
          margin-top: 14px;
        }

        &-wrapper {
          margin-top: 4px;
        }

        &-info {
          &.apply {
            letter-spacing: -0.48px;
          }
        }
      }

      &__item {
        margin-top: 17px;

        &:nth-child(2) {
          margin-top: 19px;
        }

        &-detail {
          font-size: 14px;
          line-height: 23px;

          &::before {
            top: 10px;
          }

          &.info {
            line-height: 26px;
          }
        }

        &.apply {
          margin-top: 13px;

          &:nth-child(2) {
            margin-top: 11px;
          }
        }
      }

      &__info {
        margin-top: 9px;

        &.second {
          margin-top: 17px;
        }
      }

      &__table {
        margin-top: 12px;

        &.pc {
          display: none;
        }

        &.mo {
          display: block;
        }
      }

      &__tr {
        display: flex;
        flex-direction: column;
      }

      &__td {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 49px;
        border-width: 1px 0 0 0;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: 100%;
        }

        &:last-child {
          border-width: 1px 0 1px 0;
        }

        &-title {
          border-width: 0 0 0 0;
          font-weight: 700;
          letter-spacing: -0.42px;
        }
      }

      &__responsible {
        width: 100%;
        padding: 25px 13px 15px 25px;

        &-wrap {
          flex-direction: column;
          margin-top: 14px;
        }

        &-title {
          line-height: 23px;
          letter-spacing: -0.42px;
        }

        &-item {
          margin-top: 3px;
          font-size: 14px;
          line-height: 23px;

          &:first-child {
            margin-top: 6px;
          }

          &:nth-child(2) {
            margin-top: 1px;
          }
        }

        &::before {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 50px);
          height: 1px;
          z-index: 1;
        }

        &:nth-child(2) {
          padding: 18px 13px 26px 25px;
        }
      }
    }
  }
}
